import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColumnDirective, ColumnsDirective,
    GridComponent, Grid, Inject, Page, Sort, Filter, Resize,
    Toolbar, ExcelExport, PdfExport, ColumnChooser
} from '@syncfusion/ej2-react-grids';
import { getValue } from '@syncfusion/ej2-base';
import { AggregatesDirective, Aggregate, AggregateColumnsDirective, AggregateDirective, AggregateColumnDirective }
    from '@syncfusion/ej2-react-grids';
import HttpClient from './../../../services/httpClient';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dropdown, Select } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useTranslation } from "react-i18next";
import { finishLoading, setTitle, startLoading } from "./../../../store/actions/uiAction";
import { logReportService } from "./../../../services/logReportService";
import ComboHotelSala from "./../../../components/combo-hotel-sala/combo-hotel-sala";

const ManifestReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [rowData, setRowData] = useState([]);
    const [NameSala, setNameSala] = useState([]);
    const [NameHotel, setNameHotel] = useState([]);
    const user = useSelector(state => state.auth.currentUser);
    const { idSaleRoom, idHotel } = useSelector(state => state.user.param);
    let grid = null;

    const toolbarOptions = ['ExcelExport', 'ColumnChooser'];
    
   
    const options = [
        { key: 'excel', icon: 'edit', text: 'Export to excel', value: 'excel' },
        { key: 'pdf', icon: 'delete', text: 'Export to pdf', value: 'pdf' },
    ]

    const onChangeDateStart = (event, data) => setDateStart(data.value);
    const onChangeDateEnd = (event, data) => setDateEnd(data.value);

    const getDataReport = (url) => {
        dispatch(startLoading());
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
                .then(response => {
                    dispatch(finishLoading());
                    resolve(response);
                })
                .catch(error => {
                    dispatch(finishLoading());
                    eject(error);
                })
        });
    }

    const handleViewButton = () => {
        getManifestReport();
    }

    const exportExcelFromAPI = () => {
        const base_url = process.env.REACT_APP_URL_API_PATH;
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');
        var download_excel_url = `${base_url}/reports/manifest-export-xls/${date1}/${date2}/${idSaleRoom}/${idHotel}`;
        //console.log(download_excel_url);
        window.open(download_excel_url, '_parent');
    }


    const exportExcelTotalFromAPI = () => {
        const base_url = process.env.REACT_APP_URL_API_PATH;
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');
        var download_excel_url = `${base_url}/reports/manifest-export-all-xls/${date1}/${date2}/${idSaleRoom}/${idHotel}`;
        //console.log(download_excel_url);
        window.open(download_excel_url, '_parent');
    }

    const getManifestReport = () => {
        const date1 = dateStart.toISOString().slice(0, 10).replace(/-/g, '');
        const date2 = dateEnd.toISOString().slice(0, 10).replace(/-/g, '');

        getDataReport(`/catalog/getNameSalavtaId/${idSaleRoom}`).then((response) => {
            if (response.status === 200) {
                setNameSala(response.data);
            }
        })

        getDataReport(`/catalog/getNameHotelId/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setNameHotel(response.data);
            }
        })

        getDataReport(`/reports/manifest/${date1}/${date2}/${idSaleRoom}/${idHotel}`).then((response) => {
            if (response.status === 200) {
                setRowData(response.data);
                logReportService.add('Manifest Report', user);
            }
        })
    }

    // Reporte
    const handleButtonOption = (opt) => {
        if (grid && opt.target.innerText === 'Export to excel') {
            //export2Excel();
            exportExcelFromAPI();
        } else {
            if (grid && opt.target.innerText === 'Export to pdf') {
                grid.pdfExport();
            }
        }
    }

    const export2Excel = () => {

        const date1 = dateStart.toDateString();
        const date2 = dateEnd.toDateString();


        const excelExportProperties = {
            fileName: t('menusidebar.label.manifest-report') + ".xlsx",
            theme: {
                caption: { fontName: 'Calibri', fontColor: '#666666', fontSize: 13 },
                header: { fontName: 'Calibri', fontColor: '#000000', backColor: '#3F97BF', fontSize: 13 },
            },
            header: {
                headerRows: 5,
                rows: [
                    { cells: [{ colSpan: 4, value: "LVC KCS " + t('menusidebar.label.manifest-report'), style: { fontColor: '#000000', fontSize: 16, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "SALES ROOM: " + NameSala[0]["name"], style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: "HOTELS: " + NameHotel[0]["name"], style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                    { cells: [{ colSpan: 4, value: date1 + " - " + date2, style: { fontColor: '#000000', fontSize: 14, hAlign: 'Left', bold: true, } }] },
                ]
            }
        }
        grid.excelExport(excelExportProperties);
    }

    const handleExport2Excel = () => {
        if (grid) {
            export2Excel();
        }
    }

    const handleToolbarClick = (args) => {
        if (grid && args.item.id === 'grid_excelexport') {
            export2Excel();
        } else {
            if (grid && args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            }
        }
    }

    const pageOptions = {
        pageSize: 50, pageSizes: true
    };


    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    }

    function footerSum(props) {
        return (<span>{props.Sum}</span>);

    }

    function customAggregateFnSumCalifQ() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {


            if (gridData[i]['oop'] == '0') {
                gridData[i]['calif'] == 'Q' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
            }

        }

        return "Q:" + totalSum1;

    }



    function customAggregateFnSumCalifNQ() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i]['oop'] == '0') {
                gridData[i]['calif'] == 'NQ' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;

            }
        }

        return "NQ:" + totalSum1;

    }

    function customAggregateFnSumCalifCT() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i]['oop'] == '0') {
                gridData[i]['calif'] == 'CT' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
            }
        }

        return "CT:" + totalSum1;

    }


    function customAggregateFnSumCalifInout() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i]['oop'] == '0') {
                gridData[i]['calif'] == 'IN & OUT' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
            }
        }

        return "IN & OUT:" + totalSum1;

    }

    function customAggregateFnSumvtaDSL() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            gridData[i]['empresa'] == 'DSL' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }

        return "DSL:" + totalSum1;

    }


    function customAggregateFnSumVtaTDVC() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            gridData[i]['empresa'] == 'TDVC' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;
        }
        return "TDVC:" + totalSum1;
    }

    function customAggregateFnAvgDSL() {

        var gridData = grid.dataSource;
        // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        var totalSum2 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            totalSum2 = gridData[i]['empresa'] ? totalSum2 = totalSum2 + 1 : totalSum2 = totalSum2;

            gridData[i]['empresa'] == 'DSL' ? totalSum1 = totalSum1 + 1 : totalSum1 = totalSum1;

        }

        let avg = 0;

        totalSum2 == 0 ? avg = 0 : avg = totalSum1 * 100 / totalSum2;

        avg = Number((avg).toFixed(1));

        // return the calculated value
        return   "% VENTAS:" + avg + "%";

    }


    function customAggregateFnSumVolumenvtaDSL() {
        var gridData = grid.dataSource; // gridObject.dataSource returns entire data if the grid having local dataSource
        var totalSum1 = 0;
        // do your calculation as you want with the entire dataset
        for (var i = 0; i < gridData.length; i++) {

            gridData[i]['empresa'] == 'DSL' ? totalSum1 = totalSum1 + gridData[i]['salespriceproc'] : totalSum1 = totalSum1;
        }

        return "VOLUMEN:" + totalSum1;

    }


    // Fin Reporte

    useEffect(() => {
        dispatch(setTitle(t('menusidebar.label.manifest-report')));
    }, []);

    const rowDataBound = (args) => {
        if (args.row) {
            if (getValue('oop', args.data) == '1') {
                console.log("args", args.data.lastname);
                args.row.classList.add('below-80');
            }
            else if (getValue('opp', args.data) == 0) {
                args.row.classList.add('below-30');
            }
            else {
                args.row.classList.add('above-80');
            }
        }
    };

    return (
        <div className="container-fluid">
            <ComboHotelSala />
            <div className="row col-md-12">
                <div className="col-md-3 form-group">
                    <label >Date start:</label>
                    <SemanticDatepicker className="col-md-12" name="dateStart" value={dateStart} onChange={onChangeDateStart} />

                </div>
                <div className="col-md-3  form-group ">

                    <label >Date end:</label>
                    <SemanticDatepicker className="col-md-12" name="dateEnd" value={dateEnd} onChange={onChangeDateEnd} />

                </div>
                <div className="col-md-6  form-group">
                    <Button primary onClick={handleViewButton}>View Report</Button>
                    <Button positive onClick={exportExcelFromAPI}>Export Report By Hotel</Button>
                    {/*<Button positive onClick={exportExcelTotalFromAPI}>Export Report All</Button>*/}
                    {/*<Button.Group className="col-md-3" color='orange'>
                        <Button onClick={handleExport2Excel}>Print Report</Button>
                        <Dropdown
                            className='button icon'
                            floating
                            options={options}
                            onChange={handleButtonOption}
                            trigger={<></>}
                        />
                        </Button.Group>*/}


                </div>
            </div>
            <div>
                <GridComponent id="grid" height='100%' width='auto'
                    dataSource={rowData}
                    allowSorting={true}
                    allowResizing={true}
                    allowExcelExport={true}
                    allowPdfExport={false}
                    toolbar={toolbarOptions}
                    showColumnChooser={true}                   
                    toolbarClick={handleToolbarClick}
                    dataBound={dataBound}
                    rowDataBound={rowDataBound}
                    allowPaging={true}
                    pageSettings={pageOptions}
                    ref={g => grid = g}

                >
                    <Inject services={[Sort, Toolbar, Resize, ExcelExport, PdfExport, Aggregate, Page,ColumnChooser]} />
                    <ColumnsDirective>
                        <ColumnDirective field='rownum' headerText="Num" minWidth='5' textAlign="Left" />    
                        <ColumnDirective field='lead' headerText="Lead" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='reservid' headerText="Reserv. ID" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='lastname' headerText="LastName" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='firstname' headerText="FirstName" minWidth='20' textAlign="Left" />
                        <ColumnDirective field='age' headerText="Age" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='mstatus' headerText="M.status" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='occupation' headerText="Occupation" minWidth='10' textAlign="Right" />
                        <ColumnDirective field='country' headerText="Country" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='agency' headerText="Agency" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='hotel' headerText="Hotel" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='room' headerText="Room" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pax' headerText="Pax" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='chkin' headerText="Chk-In" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='chkout' headerText="Chk-Out" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='showd' headerText="Show D." minWidth='10' textAlign="Left" />
                        <ColumnDirective field='in' headerText="In" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='out' headerText="Out" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='calif' headerText="Calif" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='inout' headerText="IN OUT" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='wo' headerText="WO" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pr1noemploye' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pr1' headerText="Concierge 1" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pr2noemploye' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='pr2' headerText="Concierge 2" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='linernoemploye' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='liner' headerText="Liner" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftmnoemp' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftm' headerText="FTM" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftbnoemp' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='ftb' headerText="FTB" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='closernoemployee' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='closer' headerText="Closer" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='exitclosernoemp' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='exitcloser' headerText="Exit Closer" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='vlonoemp' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='vlo' headerText="VLO" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='hostnoemp' headerText="# Emp" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='host' headerText="Host" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='empresa' headerText="Empresa" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='processable' headerText="Processable" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='salespriceproc' headerText="Sales Price" minWidth='10' textAlign="Left" format="C2" />
                        <ColumnDirective field='oop' headerText="OOP" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='salespriceoop' headerText="Sales Price OOP" minWidth='10' textAlign="Left" format="C2" />
                        <ColumnDirective field='pending' headerText="Pending" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='salespricepend' headerText="Sales Price" minWidth='10' textAlign="Left" format="C2" />
                        <ColumnDirective field='fechaVta' headerText="Sale date" Width='10' textAlign="center" />
                        <ColumnDirective field='fechaProceso' headerText="Proc date" minWidth='10' textAlign="center" />
                        <ColumnDirective field='membership' headerText="Membership" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='typesale' headerText="Tipo Venta" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='statuscto' headerText="Estatus" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='unittype' headerText="Unit Type" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='membtype' headerText="Memb. Type" minWidth='10' textAlign="Left" />
                        <ColumnDirective field='closingc' headerText="Closing C." minWidth='10' textAlign="Left" format="C2" />
                        <ColumnDirective field='tipoinvitacion' headerText="Invitation type" minWidth='10' textAlign="Left" />

                        <ColumnDirective field='comments' headerText="Comments" minWidth='10' textAlign="Left" />
                    </ColumnsDirective>
                    <AggregatesDirective>
                        <AggregateDirective>
                            <AggregateColumnsDirective>
                                <AggregateColumnDirective field='showd' type='Custom' customAggregate={customAggregateFnSumCalifQ} />
                                <AggregateColumnDirective field='vlonoemp' type='Custom' customAggregate={customAggregateFnSumvtaDSL} />
                                <AggregateColumnDirective field='vlo' type='Custom'  customAggregate={customAggregateFnAvgDSL} />
                                <AggregateColumnDirective field='hostnoemp' type='Custom' customAggregate={customAggregateFnSumVolumenvtaDSL} />
                                 <AggregateColumnDirective field='in' type='Custom' customAggregate={customAggregateFnSumCalifNQ} />
                                <AggregateColumnDirective field='out' type='Custom' customAggregate={customAggregateFnSumCalifCT} />
                                <AggregateColumnDirective field='calif' type='Custom' customAggregate={customAggregateFnSumCalifInout} />
                                <AggregateColumnDirective field='inout' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='wo' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='processable' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='salespriceproc' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='oop' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='salespriceoop' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='pending' type='Sum' customAggregate={footerSum} />
                                <AggregateColumnDirective field='salespricepend' type='Sum' format="C2" customAggregate={footerSum} />
                                <AggregateColumnDirective field='closingc' type='Sum' format="C2" customAggregate={footerSum} />
                            </AggregateColumnsDirective>
                        </AggregateDirective>
                    </AggregatesDirective>
                </GridComponent>
            </div>
        </div>
    );
}

export default ManifestReport;
